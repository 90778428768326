import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalOverlay } from '@chakra-ui/react'

const ModalCustom = ({ isOpen, onClose, children, hideCloseButton, p, closeOnOverlayClick, ...props }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered hideCloseButton closeOnOverlayClick={closeOnOverlayClick}>
      <ModalOverlay zIndex={6000} />
      <ModalContent containerProps={{ zIndex: 6000 }} {...props}>
        {!hideCloseButton && <ModalCloseButton />}
        <ModalBody p={p}>{children}</ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default ModalCustom
