import formatMealtime from '@/utils/formatMealTime'
import { Stack } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import MealCard from './MealCard'

const MealDashboard = () => {
  const dispatch = useDispatch()
  const { meals, date } = useSelector((state) => state.mealplan)

  useEffect(() => {
    dispatch.mealplan.getDashboard()
  }, [dispatch.mealplan, date])

  return (
    <Stack spacing={4} id="mealplan">
      {meals.map((meal) => (
        <MealCard id={formatMealtime(meal.mealtime)} key={meal.mealtime} meal={meal} />
      ))}
    </Stack>
  )
}

export default MealDashboard
