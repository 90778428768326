import DatePickerModal from '@/components/common/inputs/DatePickerModal'
import { Box, Stack, Text, useDisclosure } from '@chakra-ui/react'
import format from 'date-fns/format'
import { id } from 'date-fns/locale'
import { useDispatch, useSelector } from 'react-redux'

const MealPlanDate = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const dispatch = useDispatch()
  const date = useSelector((state) => state.mealplan.date)
  const setDate = (date) => {
    dispatch.mealplan.setDate(date)
    onClose()
  }
  return (
    <Stack bg="white" mt={4} p={4}>
      <Stack>
        <Text color="#6E7191" fontSize="12px">
          Pilih tanggal untuk calorie counter dan meal plan
        </Text>
        <Box p="10px 16px" border="0.8px solid #A0A3BD" borderRadius="8px" onClick={onOpen}>
          <Text fontWeight="bold" color="#185ADB">
            {format(date, 'EEEE, dd MMMM yyyy', { locale: id })}
          </Text>
        </Box>
      </Stack>
      <DatePickerModal isOpen={isOpen} onClose={onClose} selected={date} setSelected={setDate} />
    </Stack>
  )
}

export default MealPlanDate
