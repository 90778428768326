import { GetAdsList, GetAdsPopup, GetAdsSponsored } from '@/apis/AdsApi'
import { useQuery } from '@tanstack/react-query'

export const useGetAdsList = () => {
  const query = useQuery({
    queryKey: ['ads-list'],
    queryFn: () => GetAdsList(),
    select: (res) => res.data.data,
    staleTime: 1000 * 60 * 60 * 6,
  })

  return {
    ...query,
  }
}

export const useGetAdsPopup = () => {
  const query = useQuery({
    queryKey: ['ads-popup'],
    queryFn: () => GetAdsPopup(),
    select: (res) => res.data.data,
    staleTime: 1,
  })

  return {
    ...query,
  }
}

export const useGetAdsSponsored = () => {
  const query = useQuery({
    queryKey: ['ads-sponsored'],
    queryFn: () => GetAdsSponsored(),
    select: (res) => res.data.data,
    staleTime: 1000 * 60 * 60 * 6,
  })

  return {
    ...query,
  }
}
