import { HStack, Image, Text } from '@chakra-ui/react'

const DailyDropsFree = () => {
  return (
    <HStack w="100%" px={4}>
      <Image src="/icons/pills.svg" alt="" />
      <Text fontSize="10px">
        Pastikan kamu memperbarui measurement setiap minggu untuk melihat progresmu. Disarankan mengisi setiap hari Rabu
        atau Kamis pagi setelah bangun tidur.
      </Text>
    </HStack>
  )
}

export default DailyDropsFree
