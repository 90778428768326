import useDailyDropIndex from '@/hooks/useDailyDropsIndex'
import { HStack, Image, SkeletonText, Stack, Text } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { dailyDropTexts } from './constants'

const DailyDrops = () => {
  const [dailyDropText, setDailyDropText] = useState(null)
  const index = useDailyDropIndex()
  useEffect(() => {
    setDailyDropText(dailyDropTexts[index])
  }, [index])

  return (
    <HStack px={4} py={4} spacing={4} bg="#f4f6fa">
      <Image src="/images/daily-drops.png" alt="" />
      <Stack w="100%">
        <HStack>
          <Image src="/icons/pills.svg" alt="" />
          <Text fontWeight="bold">Daily Drops</Text>
        </HStack>
        {dailyDropText ? (
          <Text fontSize="10px">{dailyDropText}</Text>
        ) : (
          <SkeletonText noOfLines={4} startColor="gray.300" />
        )}
      </Stack>
    </HStack>
  )
}

export default DailyDrops
