import { Button } from '@chakra-ui/react'
import React from 'react'

const BtnDangerOutline = ({ children, onClick, label, ...props }) => {
  return (
    <Button
      variant="outline"
      borderColor={'#FF2C4B'}
      color="#FF2C4B"
      w="100%"
      onClick={onClick}
      _hover={{ bg: 'white' }}
      _active={{ bg: 'white' }}
      {...props}
    >
      {label}
    </Button>
  )
}

export default BtnDangerOutline
