import { Box, Button, Center, Circle, Image } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import ModalCustom from '../modals/ModalCustom'
import { useGetAdsPopup } from './ads.action'

const AdsPopup = () => {
  const { data: ads } = useGetAdsPopup()
  const [open, setOpen] = useState(false)

  useEffect(() => {
    const lastClosed = localStorage.getItem('lastClosed')
    const lastClicked = localStorage.getItem('lastClicked')
    const now = new Date().getTime()
    const twoDays = 2 * 24 * 60 * 60 * 1000
    const fiveDays = 5 * 24 * 60 * 60 * 1000

    if ((!lastClosed || now - lastClosed > twoDays) && (!lastClicked || now - lastClicked > fiveDays)) {
      setOpen(true)
    }
  }, [])

  const handleClose = () => {
    localStorage.setItem('lastClosed', new Date().getTime().toString())
    setOpen(false)
  }

  const handleClick = () => {
    localStorage.setItem('lastClicked', new Date().getTime().toString())
    window.open(ads?.[0]?.referrer_link, '_blank')
    setOpen(false)
  }

  return (
    <>
      {ads?.[0]?.attachment && (
        <ModalCustom isOpen={open} onClose={handleClose} hideCloseButton p={4} bg="none" closeOnOverlayClick={false}>
          <Box position="relative">
            <Image src={ads?.[0]?.attachment} alt="ads" borderRadius="20px" cursor="pointer" onClick={handleClick} />
            <Center mt="-20px">
              <Button
                w="80%"
                bg={ads?.[0]?.button_color ?? '#57A2A9'}
                borderRadius="20px"
                _focusVisible={{ outline: 'none' }}
                _hover={{ bg: '#57A2A9' }}
                onClick={handleClick}
              >
                {ads?.[0]?.button_text ?? 'Lihat'}
              </Button>
            </Center>
            <Circle
              bg="#D9D9D9"
              position="absolute"
              top={-8}
              right={-2}
              p={1}
              borderRadius="full"
              cursor="pointer"
              onClick={handleClose}
            >
              <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z"
                  fill="#185ADB"
                />
              </svg>
            </Circle>
          </Box>
        </ModalCustom>
      )}
    </>
  )
}

export default AdsPopup
