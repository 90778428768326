import BtnDangerOutline from '@/components/common/buttons/BtnDangerOutline'
import BtnPrimary from '@/components/common/buttons/BtnPrimary'
import ModalCustom from '@/components/common/modals/ModalCustom'
import dateIsWithinNext90Days from '@/utils/dateIsWithinNext90Days'
import gglRound from '@/utils/gglRound'
import { Badge, Box, Center, Divider, HStack, IconButton, Image, Stack, Text, useToast } from '@chakra-ui/react'
import { useRouter } from 'next/router'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'

const MealCard = ({ meal, id }) => {
  const [showAll, setShowAll] = useState(false)
  // set default number of meals to show before clicking hide/show all
  const [numToShow, setNumToShow] = useState(999)
  const [openDeleteModal, setOpenDeleteModal] = useState(false)

  const dispatch = useDispatch()
  const router = useRouter()
  const toast = useToast()

  const date = useSelector((state) => state.mealplan.date)
  const validDate = dateIsWithinNext90Days(date)

  const handleClick = () => {
    dispatch.mealplan.setMealTime(meal.mealtime)
    dispatch.mealplan.getInitialFoodKeywordSuggestion({ name: '' })
    router.push(`/meal-plan?mealtime=${meal.mealtime}`)
  }

  const handleEdit = (item) => {
    dispatch.mealplan.setSelectedMeal(item)
    router.push('/meal-plan/edit')
  }

  const handleShowHideClick = () => {
    setShowAll(!showAll)
    setNumToShow(showAll ? meal.meals.data.length : 2)
  }

  const handleDeleteAll = () => {
    const ids = meal.meals.data.map((item) => item.id)
    dispatch.mealplan.deleteMultipleMealPlans(ids).then(() => {
      setOpenDeleteModal(false)
      toast({
        render: () => (
          <Center p={3} bg="#4E4B66" borderRadius="8px" mb="72px">
            <Text color="white" fontWeight={500} fontSize="14px">
              Makanan berhasil dihapus
            </Text>
          </Center>
        ),
        duration: 2000,
      })
    })
  }

  return (
    <Stack cursor="pointer" spacing={4} bg="white" py={4} id={id}>
      <HStack justifyContent="space-between" alignItems="center" px={2}>
        <HStack spacing={8} onClick={() => setOpen(!open)}>
          <Stack>
            <Text fontWeight="bold" fontSize="14px">
              {meal.mealtime.replace('Snack', 'Cemilan')}
            </Text>
            <HStack>
              <Text>K: {gglRound(meal.total_calorie)}</Text>
              <Text>L: {gglRound(meal.total_fat)}</Text>
              <Text>P: {gglRound(meal.total_protein)}</Text>
              <Text>G: {gglRound(meal.total_sugar)}</Text>
              <Text>S: {gglRound(meal.total_fiber)}</Text>
            </HStack>
          </Stack>
        </HStack>
        <IconButton
          borderRadius="8px"
          bg="button.default"
          icon={<Image src="/icons/plus.svg" alt="" />}
          onClick={handleClick}
          isDisabled={!validDate}
        />
      </HStack>
      <Box>
        <Divider bg="#D9DBE9" h="1px" />
        <Stack p={2}>
          {meal?.meals?.data.length > 0 ? (
            meal.meals.data.slice(0, numToShow).map((item, index) => (
              <Box key={item.food.data.id + index}>
                <Stack spacing="8px" fontWeight={500} onClick={() => handleEdit(item)}>
                  <HStack alignItems="center" justifyContent="space-between">
                    <HStack alignItems="start" justifyContent="space-between" w="100%">
                      <Text fontSize={14} fontWeight={600} color="default.title">
                        {item.food.data.name}
                        <br />
                        <Text fontSize={14} fontWeight={500} as="span" color="default.label">
                          {item.serving}
                        </Text>
                      </Text>
                      {item.label?.data && (
                        <Box>
                          <Badge
                            borderRadius="6px"
                            bgColor={item.label.data.bg_color}
                            color={item.label.data.text_color}
                            textTransform="initial"
                          >
                            {item.label.data.label}
                          </Badge>
                        </Box>
                      )}
                    </HStack>
                    <HStack alignSelf="end">
                      <Image src="/icons/chevron-right.svg" alt="" w="10px" />
                    </HStack>
                  </HStack>
                  <HStack alignItems="center" justifyContent="space-between">
                    <HStack>
                      <Text color="#4E4B66">K: {item.carbohydrate}</Text>
                      <Text color="#4E4B66">L: {item.fat}</Text>
                      <Text color="#4E4B66">P: {item.protein}</Text>
                      <Text color="#4E4B66">G: {item.sugar}</Text>
                      <Text color="#4E4B66">S: {item.fiber}</Text>
                    </HStack>
                    <Text fontWeight="bold" color="default.body">
                      {gglRound(item.calorie)} kkal
                    </Text>
                  </HStack>
                </Stack>
                <Divider bg="#D9DBE9" h="1px" mt={2} />
              </Box>
            ))
          ) : (
            <>
              <Text color="default.line" textAlign="center" fontWeight={500} my={2}>
                No meal plan data available
              </Text>
              <Divider bg="#D9DBE9" h="1px" />
            </>
          )}
        </Stack>

        {meal?.meals?.data.length > 1 && (
          <HStack alignItems="center" justifyContent="space-around" p={4} pt={2} borderColor="default.line">
            <Text
              w="100%"
              textAlign="center"
              color={validDate ? 'button.default' : '#E2EBFC'}
              fontWeight="semibold"
              onClick={validDate ? () => setOpenDeleteModal(true) : () => {}}
              cursor={validDate ? 'pointer' : 'not-allowed'}
            >
              Hapus Semua
            </Text>
            {meal?.meals?.data.length > 2 && (
              <Text
                w="100%"
                borderLeft="1px solid"
                borderColor="default.line"
                textAlign="center"
                color="button.default"
                fontWeight="semibold"
                onClick={handleShowHideClick}
                cursor="pointer"
              >
                {showAll ? 'Lihat Semua' : 'Sembunyikan'}
              </Text>
            )}
          </HStack>
        )}

        <HStack
          justifyContent="space-between"
          alignItems="center"
          p={2}
          pt={4}
          borderTop={meal?.meals?.data.length > 1 && '1px solid'}
          borderColor="default.line"
        >
          <Text fontWeight={600} color="#4E4B66">
            Total Kalori
          </Text>
          <Text fontWeight="bold" fontSize="14px" color="#185ADB">
            {gglRound(meal.total_calorie)} kkal
          </Text>
        </HStack>
      </Box>

      <ModalCustom w="auto" p={4} isOpen={openDeleteModal} onClose={() => setOpenDeleteModal(false)} hideCloseButton>
        <Stack spacing="24px" alignItems="center" justifyContent="center">
          <Image w={200} src="/images/stop-confirmation.svg" alt="" />
          <Stack spacing="8px" alignItems="center">
            <Text fontWeight={700} fontSize={16}>
              Hapus Semua Makanan?
            </Text>
            <Text fontWeight={500} textAlign="center">
              Apakah kamu yakin menghapus semua
              <br />
              Makanan?
            </Text>
          </Stack>
          {/* dummy invisible input to avoid auto focus on other input */}
          <input style={{ position: 'absolute', left: '-9999px' }} autoFocus />
          <HStack w="100%" alignItems="center" justifyContent="center">
            <BtnDangerOutline px="24px" label="Ya, Hapus" onClick={handleDeleteAll} />
            <BtnPrimary px="24px" label="Tidak, Batalkan" onClick={() => setOpenDeleteModal(false)} />
          </HStack>
        </Stack>
      </ModalCustom>
    </Stack>
  )
}

export default MealCard
