import store from '@/store'

export const requireAuth = (WrappedComponent) => {
  const Wrapper = (props) => {
    if (typeof window !== 'undefined') {
      const accessToken = localStorage.getItem('access_token')
      const user = store.getState().user.user

      if (!accessToken) {
        window.location.href = `/auth/login?redirect=${window.location.pathname.substring(1)}`
        return null
      }
      if (user?.email_verified_at === null) {
        window.location.href = '/auth/verification'
        return null
      }
      if (user?.gender === null || user?.body_weight === 0 || user?.body_height === 0) {
        window.location.href = '/auth/personal-info'
        return null
      }
      if (user?.is_active === 0) {
        window.location.href = '/auth/reactivate'
        return null
      }
    }

    return <WrappedComponent {...props} />
  }

  return Wrapper
}

export default requireAuth
