import BtnUpgrade from '@/components/common/buttons/BtnUpgrade'
import ModalCustom from '@/components/common/modals/ModalCustom'
import { Button, Heading, Image, Stack, Text } from '@chakra-ui/react'

const NutritionUpgradeModal = ({ isOpen, onClose }) => {
  return (
    <ModalCustom isOpen={isOpen} onClose={onClose} hideCloseButton>
      <Stack py="24px" spacing="24px" alignItems="center" justifyContent="center">
        <Heading>Penghitung Kalori</Heading>
        <Image src="images/nutrition-counter-premium.svg" alt="" w="100%" />
        <Text color="default.title" fontWeight={500} textAlign="center" w="100%">
          Dapatkan indikator perhitungan asupan nutrisi harianmu dengan GGL Premium. Indikator lebih akurat dan mudah
          dipahami.
        </Text>
        <Stack w="100%">
          <Button variant="outline" onClick={onClose} w="100%" mb={1}>
            Oke
          </Button>
          <BtnUpgrade w="100%" />
        </Stack>
      </Stack>
    </ModalCustom>
  )
}

export default NutritionUpgradeModal
