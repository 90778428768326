import { Center, Modal, ModalBody, ModalContent, ModalOverlay } from '@chakra-ui/react'
import { DayPicker } from 'react-day-picker'

const DatePickerModal = ({ isOpen, onClose, selected, setSelected }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent borderRadius="8px">
        <ModalBody>
          <Center>
            <DayPicker
              captionLayout="dropdown"
              fromYear={1900}
              // if current month is December, able to select next year
              toYear={new Date().getMonth() === 11 ? new Date().getFullYear() + 1 : new Date().getFullYear()}
              mode="single"
              selected={selected}
              onSelect={setSelected}
            />
          </Center>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default DatePickerModal
