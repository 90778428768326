import useIsPremium from '@/hooks/useIsPremium'
import {
  Box,
  HStack,
  Image,
  Select,
  Skeleton,
  iSkeletonText,
  Stack,
  Switch,
  Text,
  SkeletonText,
} from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import NutritionBadge from './NutritionBadge'
import NutritionUpgradeModal from './NutritionUpgradeModal'
import { translateDataMealplanToTooltips, translateToPercentage } from '@/utils/mealplanHelper'
import { format } from 'date-fns'

const NutritionCounter = ({ goal, setGoal, dietCarbo, setDietCarbo }) => {
  const [nutritionNeeded, setNutritionNeeded] = useState(null)
  const [openPremiumModal, setOpenPremiumModal] = useState(false)
  const [unit, setUnit] = useState('gr')

  const isPremium = useIsPremium()

  const nutritionNames = {
    carbohydrate: 'Karbo',
    fat: 'Lemak',
    protein: 'Protein',
    sugar: 'Gula',
    fiber: 'Serat',
  }

  const dispatch = useDispatch()
  const mealPlanDate = useSelector((state) => state.mealplan.date)

  useEffect(() => {
    dispatch.mealplan
      .getNutritionNeeded({
        goal: goal,
        diet_carbo: dietCarbo,
        date: format(mealPlanDate, 'yyyy-MM-dd'),
      })
      .then(({ data }) => {
        setNutritionNeeded(data)
      })
  }, [goal, dietCarbo, mealPlanDate, dispatch.mealplan])

  const meals = useSelector((state) => state.mealplan.meals)

  const [flatMeals, setFlatMeals] = useState([])
  useEffect(() => {
    const flatMeals = meals.flatMap((meal) => {
      if (meal.meals?.data) {
        return meal.meals.data
      } else {
        return []
      }
    })
    setFlatMeals(flatMeals)
  }, [meals])

  return (
    <>
      <Stack
        bg="#C5D6FA"
        p={4}
        spacing={4}
        onClick={!isPremium ? () => setOpenPremiumModal(true) : null}
        cursor={!isPremium ? 'pointer' : 'default'}
      >
        <HStack justifyContent="space-between">
          <Stack spacing={0}>
            <Text color="#4E4B66" fontSize="12px" fontWeight="semibold">
              Kalori Makanan
            </Text>
            {nutritionNeeded ? (
              <>
                <Text fontSize="40px" color="#242324" fontWeight="600">
                  {nutritionNeeded?.calorie.consumption.toFixed(0)}{' '}
                  <Box as="span" fontSize="14px" color="#6E7191">
                    kkal
                  </Box>
                </Text>
                {nutritionNeeded?.calorie.consumption < nutritionNeeded?.calorie.min && (
                  <NutritionBadge
                    status="kurang"
                    meals={translateDataMealplanToTooltips(flatMeals, 'calorie')}
                    nutritionType="calorie"
                  />
                )}
                {nutritionNeeded?.calorie.consumption >= nutritionNeeded?.calorie.min &&
                  nutritionNeeded?.calorie.consumption <= nutritionNeeded?.calorie.max && (
                    <NutritionBadge
                      status="cukup"
                      meals={translateDataMealplanToTooltips(flatMeals, 'calorie')}
                      nutritionType="calorie"
                    />
                  )}
                {nutritionNeeded?.calorie.consumption > nutritionNeeded?.calorie.max && (
                  <NutritionBadge
                    status="berlebih"
                    meals={translateDataMealplanToTooltips(flatMeals, 'calorie')}
                    nutritionType="calorie"
                  />
                )}
              </>
            ) : (
              <Skeleton h="40px" startColor="default.line" />
            )}
            <Box p="2px 4px" fontSize="12px" fontWeight={600} w="fit-content" bg="#C5D6FA">
              &nbsp;
            </Box>
          </Stack>

          <Box w="1px" h="80px" bg="#002C3F" />

          <Stack spacing={0}>
            <Text color="#4E4B66" fontSize="12px" fontWeight="semibold">
              Kalori Maintenance
            </Text>
            {nutritionNeeded ? (
              <Text fontSize="40px" color="#242324" fontWeight="600">
                {Math.round(nutritionNeeded?.calorie_maintenance)}{' '}
                <Box as="span" fontSize="14px" color="#6E7191">
                  kkal
                </Box>
              </Text>
            ) : (
              <Skeleton h="40px" startColor="default.line" />
            )}
            <Box p="2px 4px" fontSize="12px" fontWeight={600} w="fit-content" bg="#C5D6FA">
              &nbsp;
            </Box>
          </Stack>
        </HStack>

        {isPremium && (
          <HStack justifyContent="space-between">
            {Object.keys(nutritionNames).map((nutritionName) => (
              <Stack spacing={1} key={nutritionName}>
                <Text color="#242324" fontSize="12px" fontWeight="semibold">
                  {nutritionNames[nutritionName]}
                </Text>
                {nutritionNeeded ? (
                  <>
                    {(unit === 'gr' || nutritionName === 'sugar' || nutritionName === 'fiber') && (
                      <Text fontWeight="bold" fontSize="16px" color="#242324">
                        {nutritionNeeded?.[nutritionName].consumption.toFixed(1)}gr
                      </Text>
                    )}
                    {unit === '%' && nutritionName !== 'sugar' && nutritionName !== 'fiber' && (
                      <Text fontWeight="bold" fontSize="16px" color="#242324">
                        {translateToPercentage({
                          key: nutritionName === 'carbohydrate' ? 'carbo' : nutritionName,
                          valueCarbo: nutritionNeeded?.carbohydrate.consumption,
                          valueFat: nutritionNeeded?.fat.consumption,
                          valueProtein: nutritionNeeded?.protein.consumption,
                        })}
                      </Text>
                    )}
                    {nutritionNeeded?.[nutritionName].consumption < nutritionNeeded?.[nutritionName].min && (
                      <NutritionBadge
                        status="kurang"
                        meals={translateDataMealplanToTooltips(flatMeals, nutritionName)}
                        nutritionType={nutritionName}
                      />
                    )}
                    {nutritionNeeded?.[nutritionName].consumption >= nutritionNeeded?.[nutritionName].min &&
                      nutritionNeeded?.[nutritionName].consumption < nutritionNeeded?.[nutritionName].max && (
                        <NutritionBadge
                          status="cukup"
                          meals={translateDataMealplanToTooltips(flatMeals, nutritionName)}
                          nutritionType={nutritionName}
                        />
                      )}
                    {nutritionNeeded?.[nutritionName].consumption > nutritionNeeded?.[nutritionName].max && (
                      <NutritionBadge
                        status="berlebih"
                        meals={translateDataMealplanToTooltips(flatMeals, nutritionName)}
                        nutritionType={nutritionName}
                      />
                    )}
                  </>
                ) : (
                  <Skeleton h="14px" startColor="default.line" />
                )}
              </Stack>
            ))}
          </HStack>
        )}

        {!isPremium && (
          <HStack justifyContent="space-between">
            {Object.keys(nutritionNames).map((nutritionName, index) => (
              <Stack spacing={1} key={nutritionName}>
                <Text color="#4E4B66" fontSize="12px" fontWeight="semibold">
                  {nutritionNames[nutritionName]}
                </Text>
                {nutritionNeeded ? (
                  <Text fontWeight="bold" fontSize="16px" color="#242324">
                    {Math.round(nutritionNeeded?.[nutritionName].consumption)} gr
                  </Text>
                ) : (
                  <SkeletonText h="16px" startColor="default.line" />
                )}
                {(index == 0 || index == 2 || index == 3) && <NutritionBadge status="kurang" blur />}
                {(index == 1 || index == 4) && <NutritionBadge status="cukup" blur />}
              </Stack>
            ))}
          </HStack>
        )}

        {!isPremium && (
          <HStack py={2}>
            <Image src="/icons/king.svg" alt="" />
            <Text fontWeight={400} color="#002C3F">
              Silakan upgrade ke Premium untuk mendapatkan indikator dari asupan Anda setiap hari
            </Text>
          </HStack>
        )}

        <HStack alignItems="center" justifyContent="space-between">
          <Text color="#4E4B66" fontWeight="semibold" fontSize="14px">
            Ubah satuan perhitungan
          </Text>
          <Box position="relative">
            <Switch
              variant="dualSwitch"
              size="lg"
              isChecked={unit === '%'}
              onChange={() => setUnit(unit === 'gr' ? '%' : 'gr')}
            />
            <Text position="absolute" bottom="6px" right="6px" color="white" fontWeight="bold">
              gr
            </Text>
            <Text position="absolute" bottom="4.5px" left="8px" color="white" fontWeight="bold">
              %
            </Text>
          </Box>
        </HStack>
      </Stack>

      <NutritionUpgradeModal isOpen={openPremiumModal} onClose={() => setOpenPremiumModal(false)} />
    </>
  )
}

export default NutritionCounter
