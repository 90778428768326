import { Box, Center, HStack, Image, Link, Skeleton, Square } from '@chakra-ui/react'
import { useState } from 'react'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'
import { useGetAdsList } from './ads.action'

const SliderBanner = () => {
  const { data: ads } = useGetAdsList()

  const [currentSlide, setCurrentSlide] = useState(0)
  const sliderSettings = {
    dots: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    infinite: true,
    arrows: false,
    autoplaySpeed: 3000,
    beforeChange: (current, next) => setCurrentSlide(next),
    afterChange: (current) => setCurrentSlide(current),
    centerMode: true,
  }

  return (
    <Skeleton isLoaded={!!ads} height={!ads ? '160px' : '100%'}>
      <Box bg="white" mt={ads ? 4 : 0}>
        <Slider {...sliderSettings}>
          {ads?.map((ad) => (
            <Link
              _focusVisible={{ outline: 'none' }}
              href={ad.referrer_link}
              key={ad.id}
              target="_blank"
              rel="noreferrer"
            >
              <Center p={3} mr={0}>
                <Image src={ad.attachment} alt="ads" borderRadius="8px" shadow="md" />
              </Center>
            </Link>
          ))}
        </Slider>
        <HStack align="center" justify="center" pb={2} zIndex={5}>
          {ads?.map((ad, index) => (
            <Square
              key={ad.id}
              bg={currentSlide === index ? '#8BACEC' : 'transparent'}
              border={currentSlide !== index ? '2px solid #8BACEC' : 'none'}
              borderColor="#8BACEC"
              borderRadius={currentSlide === index ? '8px' : 'full'}
              w={currentSlide === index ? '8' : '2'}
              h={currentSlide === index ? '2' : '2'}
              transition="all 0.3s"
            />
          ))}
        </HStack>
      </Box>
    </Skeleton>
  )
}

export default SliderBanner
