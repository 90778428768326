export const dailyDropTexts = [
  'Pastikan kamu memperbarui measurement setiap minggu untuk melihat progresmu. Disarankan mengisi setiap hari Rabu atau Kamis pagi setelah bangun tidur.',
  'Timbang makanan saat mentah atau matang, mana yang benar? Sesuaikan dengan nama makanan yang keluar di aplikasi. Jika tulisannya ayam panggang, berarti ditimbang saat matang. Begitupun sebaliknya, jika tulisannya dada ayam, berarti timbang saat mentah.',
  'Punya asam urat dan takut buat makan sayur? Tenang, tidak semua sayur dihindari. Kamu bisa memilih jenis sayur dengan kandungan purin yang rendah seperti kol merah, paprika, wortel, kale, mentimun, selada, dan kentang.',
  'Goal belum tercapai tapi sudah kekenyangan? Untuk pemula, sebaiknya kamu meningkatkan porsi secara bertahap. Misal, naikkan konsumsi 100-200 kalori setiap 3-4 hari hingga kamu dapat mencapai goal yang telah ditentukan di aplikasi.',
  'Usahakan tidur malam selama 7 - 9 jam untuk mendapatkan hasil yang maksimal dari program GGL.',
  'Lakukan jalan 10.000 langkah per hari untuk memaksimalkan efek pembakaran lemak saat kamu berolahraga. Meskipun hari itu kamu tidak berolahraga, usahakan untuk tetap mencapai 10.000 langkah per hari.',
  'Gunakan satuan gram saat menimbang makanan untuk mendapatkan perhitungan yang lebih akurat. Jika kamu menggunakan satuan potong, mangkok, dan lainnya, kemungkinan besar hasilnya tidak akurat sebab setiap orang punya ukuran potong ataupun mangkok yang berbeda.',
  'Tambahkan beban saat berolahraga secara berkala saat kamu merasa olahragamu terasa ringan. Sebab, olahraga yang terasa ringan tidak akan memberikan hasil yang memuaskan.',
  'Gunakan fitur GGL Search untuk mendapatkan jawaban dari setiap pertanyaanmu mengenai diet.',
  'Kamu tidak perlu mengubah jumlah kalori harian jika kamu tidak berolarahraga di hari itu.',
  'Gagal sehari dalam membuat meal plan adalah hal yang normal. Dengan catatan, selanjutnya kamu harus memperbaiki progres untuk mencapai goal yang ditentukan untuk mendapatkan hasil yang optimal.',
  'Perkembangan 10% setiap hari atau minggu jauh lebih baik daripada kamu memutuskan untuk menyerah dengan alasan susah.',
  'Sejauh ini, member tertua GGL berusia 72 tahun. Hal itu membuktikan usia bukan hambatan. Kalau dia saja bisa, kenapa kamu tidak?',
  'Low carb atau moderate carb? Mayoritas member GGL saat ini menjalankan moderate carb. Namun hal itu bukan keharusan, jadi kamu bisa memilih tipe diet yang menurutmu lebih efektif.',
  'Hati-hati memilih makanan sebagai sumber lemak. Hindari gorengan, jeroan, babat, atau makanan olahan. Selain itu, tingkatkan konsumsi serat untuk menjaga kadar kolesterol. Jika ingin melakukan pengukuran kadar kolesterol, lakukan di lab resmi, bukan di kios maupun cara instan lainnya.',
  'Untuk mendapatkan hasil yang lebih cepat, pastikan kamu mendengarkan tips-tips yang disampaikan Coach Dien & Mey di setiap video olahraga. Cara itu jauh lebih efektif dibandingkan hanya mencatat nama gerakan kemudian melakukannya tanpa melihat video penjelasan.',
  'Kamu boleh menggabungkan program GGL dengan program olahraga lain jika itu membuatmu bahagia. Tapi, jika tujuanmu untuk mendapatkan hasil lebih cepat, sebaiknya jangan. Sebab, tubuhmu perlu istirahat setelah melakukan olahraga GGL untuk mendapatkan hasil yang maksimal.',
  'Ukuran badan naik setelah satu minggu adalah hal yang normal. Sebab, badan manusia berubah naik-turun jika dilihat dengan masa waktu yang singkat. Jadi, disarankan untuk melakukan penilaian/perbandingan dengan jangka waktu 1 bulan.',
  'Mana yang perlu didahulukan: pola makan atau olahraga? Kenapa tidak keduanya? Tapi, jika kamu baru mulai dan kesusahan, sebaiknya perbaiki pola makan dahulu agar badanmu mempunyai nutrisi yang cukup untuk menjalankan program olahraga GGL.',
  'Jika kebutuhan kalori harianmu mencapai 2000 kalori, kamu boleh minum suplemen protein untuk membantu memenuhi nutrisi. Tapi, jika kebutuhan kalori harianmu di bawah 2000 kalori, sebaiknya jangan menggunakan suplemen protein.',
  'Jika kamu memiliki riwayat sakit seperti kecelakaan atau kondisi khusus lainnya, silakan berkonsultasi terlebih dahulu dengan dokter untuk mendapatkan izin menjalankan program pola makan dan olahraga GGL.',
  'Jika kamu berolahraga, minum 4 - 5 liter air per hari. Tapi, jika kamu tidak berolahraga, minum minimal 3 - 4 liter air per hari.',
  'Kesusahan BAB dapat diatasi dengan memastikan jumlah kalori harian sudah sesuai arahan. Selain itu, penuhi target konsumsi serat dan minum air putih yang cukup setiap harinya (3 - 4 liter per hari jika tidak sedang berolahraga.',
]
