export default function dayTime() {
  const today = new Date()
  const curHr = today.getHours()

  if (curHr < 12) {
    return 'Pagi'
  }
  if (curHr < 15) {
    return 'Siang'
  }
  if (curHr < 20) {
    return 'Sore'
  }
  return 'Malam'
}
