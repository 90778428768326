import BtnUpgrade from '@/components/common/buttons/BtnUpgrade'
import useIsPremium from '@/hooks/useIsPremium'
import {
  Box,
  HStack,
  Heading,
  Image,
  Skeleton,
  Slider,
  SliderFilledTrack,
  SliderTrack,
  Stack,
  Text,
} from '@chakra-ui/react'
import { useRouter } from 'next/router'
import { useSelector } from 'react-redux'
import GoalSlider from './GoalSlider'
import { useEffect, useState } from 'react'
import { translateDataMealplanToTooltips } from '@/utils/mealplanHelper'

const GoalSection = () => {
  const router = useRouter()
  const { nutritionNeeded } = useSelector((state) => state.mealplan)

  const isPremium = useIsPremium()

  const user = useSelector((state) => state.user.user)
  const meals = useSelector((state) => state.mealplan.meals)

  const [flatMeals, setFlatMeals] = useState([])
  useEffect(() => {
    const flatMeals = meals.flatMap((meal) => {
      if (meal.meals?.data) {
        return meal.meals.data
      } else {
        return []
      }
    })
    setFlatMeals(flatMeals)
  }, [meals])

  if (nutritionNeeded) {
    return (
      <Box position="relative">
        <Box filter={!isPremium && 'blur(4px)'} bg="white" pb="64px">
          <Stack p={2}>
            <HStack alignItems="center" justifyContent="space-between" py={4}>
              <HStack spacing={4}>
                <Heading color="#002C3F">Goal 1</Heading>
                <Image src="/icons/goal-check.svg" alt="" />
              </HStack>
              <Image
                w="12px"
                mr={2}
                src="/icons/chevron-right.svg"
                alt=""
                onClick={() => router.push(`/goal/${user.id}/1`)}
                cursor="pointer"
              />
            </HStack>
            <Box>
              <GoalSlider
                name="Kalori"
                unit="kkal"
                min={nutritionNeeded.calorie.min}
                max={nutritionNeeded.calorie.max}
                currentValue={isPremium && nutritionNeeded.calorie.consumption}
                meals={translateDataMealplanToTooltips(flatMeals, 'calorie')}
              />
              <Box h="24px" />
              <GoalSlider
                name="Gula"
                unit="gr"
                min={nutritionNeeded.sugar.min}
                max={nutritionNeeded.sugar.max}
                currentValue={isPremium && nutritionNeeded.sugar.consumption}
                meals={translateDataMealplanToTooltips(flatMeals, 'sugar')}
              />
            </Box>
          </Stack>

          <Box h="24px" />

          <Stack p={2}>
            <HStack alignItems="center" justifyContent="space-between" py={4}>
              <HStack spacing={4}>
                <Heading color="#002C3F">Goal 2</Heading>
                <Image src="/icons/goal-min.svg" alt="" />
              </HStack>
              <Image
                w="12px"
                src="/icons/chevron-right.svg"
                alt=""
                onClick={() => router.push(`/goal/${user.id}/2`)}
                cursor="pointer"
              />
            </HStack>
            <Box h="8px" />
            {nutritionNeeded.is_goal2_unlocked && (
              <Box>
                <GoalSlider
                  name="Karbohidrat"
                  unit="kkal"
                  min={nutritionNeeded.carbohydrate.min}
                  max={nutritionNeeded.carbohydrate.max}
                  currentValue={isPremium && nutritionNeeded.carbohydrate.consumption}
                  meals={translateDataMealplanToTooltips(flatMeals, 'carbohydrate')}
                />
                <GoalSlider
                  name="Lemak"
                  unit="gr"
                  min={nutritionNeeded.fat.min}
                  max={nutritionNeeded.fat.max}
                  currentValue={isPremium && nutritionNeeded.fat.consumption}
                  meals={translateDataMealplanToTooltips(flatMeals, 'fat')}
                />
                <GoalSlider
                  name="Protein"
                  unit="gr"
                  min={nutritionNeeded.protein.min}
                  max={nutritionNeeded.protein.max}
                  currentValue={isPremium && nutritionNeeded.protein.consumption}
                  meals={translateDataMealplanToTooltips(flatMeals, 'protein')}
                />
                <GoalSlider
                  name="Serat"
                  unit="gr"
                  min={nutritionNeeded.fiber.min}
                  max={nutritionNeeded.fiber.max}
                  currentValue={isPremium && nutritionNeeded.fiber.consumption}
                  meals={translateDataMealplanToTooltips(flatMeals, 'fiber')}
                />
              </Box>
            )}

            {!nutritionNeeded.is_goal2_unlocked && (
              <Stack pt={2} spacing={4}>
                <Slider value={nutritionNeeded.goal2_unlocked_percentage * 100} size="lg" mr="24px">
                  <SliderTrack h="12px" borderRadius="12px" bg="#EFF0F6">
                    <SliderFilledTrack bg="button.default" />
                  </SliderTrack>
                </Slider>
                <Text textAlign="center">hari ke- {nutritionNeeded.goal2_unlocked_percentage * 5} / 5</Text>
                <Text textAlign="center" fontWeight={500}>
                  Goal 2 akan terbuka jika Anda sudah memenuhi Goal 1 selama 5 hari berturut-turut.
                </Text>
              </Stack>
            )}
          </Stack>
        </Box>
        {!isPremium && <BtnUpgrade position="absolute" top="50%" left="0" right="0" mx="auto" />}
      </Box>
    )
  } else {
    return <Skeleton height="100%" />
  }
}

export default GoalSection
