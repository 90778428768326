import { Button, HStack, Image, Text } from '@chakra-ui/react'
import { useRouter } from 'next/router'

const BtnUpgrade = ({ ...props }) => {
  const router = useRouter()
  return (
    <Button w="200px" bg="button.default" onClick={() => router.push('/pay')} {...props}>
      <HStack>
        <Image src="/icons/crown.svg" w="20px" alt="" />
        <Text fontWeight="700" fontSize="14px" color="white">
          Upgrade Premium
        </Text>
      </HStack>
    </Button>
  )
}

export default BtnUpgrade
