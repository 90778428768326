import { Heading, Image, Stack, Text } from '@chakra-ui/react'
import ModalCustom from './ModalCustom'
import BtnPrimary from '../buttons/BtnPrimary'

const EmptyModal = ({ isOpen, onClose }) => {
  return (
    <ModalCustom isOpen={isOpen} onClose={onClose}>
      <Stack alignItems="center" justifyContent="center" p={6} spacing={4}>
        <Image src="/images/ic500or422.png" w="200px" alt="" />
        <Heading as="h3" size="md" mt={4} mb={2} textAlign="center" fontSize="16px">
          Tunggu ya, sedang ada perbaikan
        </Heading>
        <Text fontSize="14px" textAlign="center">
          Kami sedang mengembangkan sistem untuk memberikan layanan yang terbaik untuk Anda.
        </Text>
        <BtnPrimary label="Muat Ulang" onClick={onClose} />
      </Stack>
    </ModalCustom>
  )
}

export default EmptyModal
