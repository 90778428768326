import { HStack, Select, Stack, Text } from '@chakra-ui/react'

const SelectNutritionGoal = ({ goal, setGoal, dietCarbo, setDietCarbo }) => {
  return (
    <HStack px={2} pt={4} bg="white">
      <Stack spacing={0} w="50%">
        <Text fontSize="18px" fontWeight="600" color="default.title">
          Goal
        </Text>
        <Select
          variant="filled"
          bg="white"
          border="3px solid"
          borderColor="button.default"
          borderRadius="8px"
          color="button.default"
          fontWeight="600"
          value={goal}
          onChange={(e) => setGoal(e.target.value)}
        >
          <option value="fat-loss" style={{ color: '#185ADB' }}>
            Bakar Lemak
          </option>
          <option value="busui" style={{ color: '#185ADB' }}>
            Bakar Lemak (Ibu Menyusui)
          </option>
          <option value="maintenance" style={{ color: '#185ADB' }}>
            Menjaga Berat Badan
          </option>
        </Select>
      </Stack>
      <Stack spacing={0} w="50%">
        <Text fontSize="18px" fontWeight="600" color="default.title">
          Diet
        </Text>
        <Select
          variant="filled"
          bg="white"
          border="3px solid"
          borderColor="button.default"
          borderRadius="8px"
          color="button.default"
          fontWeight="600"
          value={dietCarbo}
          onChange={(e) => setDietCarbo(e.target.value)}
        >
          <option value="low" style={{ color: '#185ADB' }}>
            Low Carbo
          </option>
          <option value="moderate" style={{ color: '#185ADB' }}>
            Moderate Carbo
          </option>
          <option value="high" style={{ color: '#185ADB' }}>
            High Carbo
          </option>
        </Select>
      </Stack>
    </HStack>
  )
}

export default SelectNutritionGoal
