import { Button } from '@chakra-ui/react'
import React from 'react'

function BtnPrimary({ label, disabled, mt = 0, onClick, w = '100%', ...props }) {
  return (
    <Button variant="solid" w={w} isDisabled={disabled} mt={mt} disabled={disabled} onClick={onClick} {...props}>
      {label}
    </Button>
  )
}

export default BtnPrimary
