import api from '@/lib/axios'
import { HStack, Image, Modal, ModalContent, Stack } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import Stories from 'react-insta-stories'

const GGLStory = () => {
  const [stories, setStories] = useState([])
  const [selectedStory, setSelectedStory] = useState({})
  const [openModal, setOpenModal] = useState(false)
  const getStories = async () => {
    const res = await api.get('/v1/home/stories')
    setStories(res.data.data)
  }
  useEffect(() => {
    getStories()
  }, [])

  return (
    <>
      <HStack overflowX="scroll" px={4} py={4}>
        {stories.map((story, index) => (
          <Image
            key={index}
            src={story.thumbnail}
            alt={story.group_name}
            borderRadius="8px"
            width="120px"
            height="150px"
            objectFit="cover"
            onClick={() => {
              setSelectedStory(story)
              setOpenModal(true)
            }}
          />
        ))}
      </HStack>
      <Modal isOpen={openModal} onClose={() => setOpenModal(false)} isCentered size="full">
        <ModalContent bg="transparent">
          <Stack
            alignItems="center"
            justifyContent="center"
            bg="black"
            height="100vh"
            margin="auto"
            width={{ base: '100%', md: '480px' }}
          >
            {selectedStory && (
              <Stories
                stories={selectedStory?.data?.map((story) => ({
                  url: story.url_media,
                  type: story.media_type,
                  duration: story.duration,
                }))}
                defaultInterval={1500}
                onAllStoriesEnd={() => setOpenModal(false)}
                width="100%"
                height="100%"
              />
            )}
          </Stack>
        </ModalContent>
      </Modal>
    </>
  )
}

export default GGLStory
