// utils/useDailyDropIndex.js
import { useState, useEffect } from 'react'

const useDailyDropIndex = () => {
  const [index, setIndex] = useState(null)

  useEffect(() => {
    const stringDateTimeSetContent = localStorage.getItem('dateSetContentDailyDrop')
    const now = new Date()
    const date = new Date(now.getFullYear(), now.getMonth(), now.getDate())

    if (stringDateTimeSetContent === null) {
      localStorage.setItem('dateSetContentDailyDrop', date.toISOString())
    }

    const dateTimeSetContent = new Date(localStorage.getItem('dateSetContentDailyDrop'))
    let index = Number(localStorage.getItem('numberSetContentDailyDrop'))

    if (dateTimeSetContent < date && now.getHours() >= 5) {
      localStorage.setItem('dateSetContentDailyDrop', date.toISOString())

      if (index === 22) {
        index = 0
      } else {
        index++
      }

      localStorage.setItem('numberSetContentDailyDrop', index.toString())
    }

    setIndex(Number(localStorage.getItem('numberSetContentDailyDrop')))
  }, [])

  return index
}

export default useDailyDropIndex
