import BtnPrimary from '@/components/common/buttons/BtnPrimary'
import ModalCustom from '@/components/common/modals/ModalCustom'
import api from '@/lib/axios'
import { Heading, Image, Modal, ModalBody, ModalContent, ModalOverlay, Stack, Text } from '@chakra-ui/react'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'

const MentorshipModal = () => {
  const router = useRouter()
  const [show, setShow] = useState(false)
  useEffect(() => {
    api
      .get('/v1/membership', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
      })
      .then((res) => {
        const data = res.data.data
        // https://github.com/awr-ggl/ops/blob/main/RFC/API-v2/Mentoring/Force-Mentoring-Flag.md
        if (data.status_mentoring === 1 || data.status_mentoring === 5) {
          setShow(true)
        }
      })
      .catch((err) => {
        setShow(false)
      })
  }, [])
  return (
    <>
      {show && (
        <ModalCustom isOpen={true} hideCloseButton>
          <Stack alignItems="center" justifyContent="center" p={6} spacing={4}>
            <Image src="/images/mentorship-popup.svg" alt="mentorship" w="200px" />
            <Heading as="h3" size="md" mt={4} mb={2} textAlign="center" fontSize="16px">
              Ada kuota bimbingan nih! 🎉
            </Heading>
            <Text fontSize="14px" textAlign="center">
              Yuk, saatnya Anda mengikuti bimbingan dari para mentor kami untuk mendapatkan hasil yang jauh lebih baik
              😍
            </Text>
            <BtnPrimary label="Daftar Bimbingan" onClick={() => router.push('/mentorship/register')} />
          </Stack>
        </ModalCustom>
      )}
    </>
  )
}

export default MentorshipModal
