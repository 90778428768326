import { Box, Stack } from '@chakra-ui/react'
import DailyDrops from './DailyDrops'
import GGLStory from './GGLStory'
import HomeHeader from './HomeHeader'
import MealPlanDate from './MealPlanDate'
import NutritionCounter from './NutritionCounter'
import MealDashboard from './MealDashboard'
import Container from '@/components/common/containers/Container'
import GoalSection from './GoalSection'
import MentorshipModal from './MentorshipModal'
import useIsPremium from '@/hooks/useIsPremium'
import DailyDropsFree from './DailyDropsFree'
import SelectNutritionGoal from './SelectNutritionGoal'
import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { useDispatch, useSelector } from 'react-redux'
import SliderBanner from '@/components/common/ads/SliderBanner'
import AdsPopup from '@/components/common/ads/AdsPopup'

const HomePage = () => {
  const router = useRouter()
  const dispatch = useDispatch()
  const isPremium = useIsPremium()
  const { goal, dietCarbo } = useSelector((state) => state.mealplan)
  const setGoal = (payload) => dispatch.mealplan.setGoal(payload)
  const setDietCarbo = (payload) => dispatch.mealplan.setDietCarbo(payload)
  const user = useSelector((state) => state.user.user)

  useEffect(() => {
    // if section is either breakfast, lunch.. etc to scroll to the section
    if (router.query.section !== undefined) {
      const element = document.getElementById(router.query.section)
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' })
      }
    }
  }, [router.query.section])

  useEffect(() => {
    dispatch.user.getProfile().then(() => {
      if (user.email_verified_at === null) {
        window.location.href = '/auth/verification'
      } else if (user?.gender === null || user?.body_weight === 0 || user?.body_height === 0) {
        window.location.href = '/auth/personal-info'
      } else if (user?.is_active === false) {
        window.location.href = '/auth/reactivate'
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // reset mealplan states when page is loaded
  useEffect(() => {
    dispatch.mealplan.resetSelectedFoods()
    dispatch.mealplan.resetSelectedSavedMeals()
    dispatch.mealplan.resetSavedMealsFormValues()
    dispatch.mealplan.setMealTime('')
  }, [])

  // reset mealTime everytime
  useEffect(() => {
    dispatch.mealplan.setMealTime('')
  }, [])

  return (
    <Container px={0} bg="#F4F6FA">
      <AdsPopup />
      <HomeHeader />
      <SliderBanner />
      <Stack spacing={4} bg="#F4F6FA">
        <MealPlanDate />
        <MealDashboard />
        <NutritionCounter goal={goal} setGoal={setGoal} dietCarbo={dietCarbo} setDietCarbo={setDietCarbo} />
        <Box>
          <SelectNutritionGoal goal={goal} setGoal={setGoal} dietCarbo={dietCarbo} setDietCarbo={setDietCarbo} />
          <GoalSection />
        </Box>
        {isPremium && <DailyDrops />}
        {!isPremium && <DailyDropsFree />}
        <GGLStory />
        <MentorshipModal />
      </Stack>
    </Container>
  )
}

export default HomePage
