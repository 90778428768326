// example use case:
// Tombol Simpan dan Hapus Meal Plan hanya muncul untuk catatan makanan di hari ini dan hari berikutnya,
// dan tidak lebih dari 90 hari ke depan.

import { differenceInDays, isToday, isPast } from 'date-fns'

function dateIsWithinNext90Days(date) {
  const today = new Date()
  return (!isPast(date) || isToday(date)) && differenceInDays(date, today) <= 90
}

export default dateIsWithinNext90Days
