import EmptyModal from '@/components/common/modals/EmptyModal'
import dayTime from '@/utils/dayTime'
import { Avatar, HStack, Image, Skeleton, Stack, Text } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

const HomeHeader = () => {
  const user = useSelector((state) => state.user.user)
  const [avatar, setAvatar] = useState('https://dev-api-v2.ggl.life/images/member-default.png')
  const [name, setName] = useState('')
  const [isMentor, setIsMentor] = useState(false)

  const [openModal, setOpenModal] = useState(false)

  useEffect(() => {
    if (user) {
      setName(user?.name)
      setAvatar(user?.avatar_full_path)
      setIsMentor(user.roles?.data[0]?.name === 'mentor')
    }
  }, [user])

  return (
    <>
      <HStack bg="white" p={2} alignItems="center" justifyContent="space-between" px={4}>
        <HStack spacing={2} w="100%">
          <Avatar size="sm" src={avatar} />
          <Stack spacing={1} w="100%">
            <Text color="#4E4B66" fontWeight={500} fontSize="12px">
              Hi, Selamat {dayTime()}
            </Text>
            {name ? (
              <Text color="#002C3F" fontWeight={500} fontSize="14px">
                {isMentor && 'Mentor '}
                {name}
              </Text>
            ) : (
              <Skeleton h="14px" startColor="gray.300" w="80%" />
            )}
          </Stack>
        </HStack>
        <Image cursor="pointer" src="/icons/notification.svg" alt="notification" onClick={() => setOpenModal(true)} />
      </HStack>
      <EmptyModal isOpen={openModal} onClose={() => setOpenModal(false)} />
    </>
  )
}

export default HomeHeader
