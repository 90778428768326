import instance from '@/lib/axios'

export const GetAdsList = async () => {
  const res = await instance.get('/v1/ads', {
    params: {
      type: 'carousel',
      placement: 'dashboard',
    },
  })
  return res
}

export const GetAdsPopup = async () => {
  const res = await instance.get('/v1/ads', {
    params: {
      type: 'popup',
      placement: 'dashboard',
    },
  })
  return res
}

export const GetAdsSponsored = async () => {
  const res = await instance.get('/v1/ads', {
    params: {
      type: 'banner',
      placement: 'searchfood',
    },
  })
  return res
}
